import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/best-neighborhoods-retirees-san-diego");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_The Best San Diego Neighborhoods_Suburbs for Retirees.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                If you're ready to enjoy your golden years in a place that offers gorgeous
                year-round weather, breathtaking coastal views, and a vibrant community, look no
                further than San Diego.
              </CommonParagraph>
              <CommonParagraph>
                Here, we'll go over factors to consider when choosing a neighborhood to settle down
                in and provide a quick overview of some of the most desirable suburbs for retirees.
              </CommonParagraph>
              <Heading type="h2">What to Look for When Choosing a Neighborhood</Heading>
              <Heading type="h3">Amenities</Heading>
              <CommonParagraph>
                You've worked hard over your lifetime–now it's time to enjoy retirement fully, and
                access to amenities can help you do just that.
              </CommonParagraph>
              <CommonParagraph>
                When considering the best San Diego neighborhoods for retirement, consider{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego"
                  text="buying a
                home"
                />{" "}
                close to recreational facilities, healthcare, and grocery stores. Easy access to
                everyday essentials can offer peace of mind, and choosing a location near cultural
                attractions, parks, and public transportation can help you maintain an active and
                fulfilling lifestyle.
              </CommonParagraph>
              <Heading type="h3">Safety</Heading>
              <CommonParagraph>
                Neighborhoods with well-maintained infrastructure, visible police presence, and low
                crime rates can help create a peaceful, relaxed living environment. Conduct thorough
                research before hitting the real estate market to narrow your search and more
                quickly find the perfect home to retire in.
              </CommonParagraph>
              <Heading type="h3">Community</Heading>
              <CommonParagraph>
                For those who enjoy get-togethers and staying socially active, you'll want to choose
                a neighborhood with a strong sense of community. Look for signs of organized events,
                community centers, and clubs, as they'll offer opportunities to stay connected with
                others and build new, meaningful relationships.
              </CommonParagraph>
              <Heading type="h2">Start Your Search In These Neighborhoods</Heading>
              <CommonParagraph>
                Not sure where to start your search for the perfect home? Here's a list of ten
                welcoming{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/"
                  text="San Diego communities"
                />
                –five with higher living costs and five with lower costs–that can help you get
                started.
              </CommonParagraph>
              <Heading type="h3">5 Suburbs With a Higher Cost of Living</Heading>
              <CommonParagraph>1. La Jolla</CommonParagraph>
              <CommonParagraph>
                Enjoy the luxurious lifestyle you've earned in the gorgeous neighborhood of La
                Jolla. Home to top-rated medical centers, boutique shopping, fine dining, and
                numerous cultural attractions like the Museum of Contemporary Art, La Jolla is a
                fantastic place to retire.
              </CommonParagraph>
              <CommonParagraph>2. Del Mar</CommonParagraph>
              <CommonParagraph>
                Boasting a serene environment, Del Mar offers golf courses, hiking trails, and the
                world-famous Del Mar fairgrounds. If you're looking for a peaceful yet active
                lifestyle, this Southern California suburb is hard to beat.
              </CommonParagraph>
              <CommonParagraph>3. Carlsbad</CommonParagraph>
              <CommonParagraph>
                Blending coasting living with suburban amenities, this family-friendly community is
                perfect for those looking for outdoor activities. From exploring the flower fields
                to going for a walk on the beach, there's always something to enjoy in the great
                outdoors of Carlsbad.
              </CommonParagraph>
              <CommonParagraph>4. Rancho Bernardo</CommonParagraph>
              <CommonParagraph>
                Home to several senior centers and retirement communities,{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/rancho-bernardo-homes-for-sale"
                  text="Rancho Bernardo"
                />{" "}
                is a popular choice for retirees. With its parks, rolling hills, and proximity to
                wineries, it's a peaceful paradise with plenty of opportunities for socializing.
              </CommonParagraph>
              <CommonParagraph>5. Universal City</CommonParagraph>
              <CommonParagraph>
                If you retire in Universal City, you'll appreciate the area's walkability and parks,
                as well as the many educational and cultural events hosted by the nearby University
                of California, San Diego. Plus, featuring an array of dining options and shopping
                centers, this suburb caters to a variety of tastes and preferences.
              </CommonParagraph>
              <Heading type="h3">5 Suburbs With a Lower Cost of Living</Heading>
              <CommonParagraph>1. Normal Heights</CommonParagraph>
              <CommonParagraph>
                Normal Heights's central location offers easy access to many parts of San Diego,
                making it the perfect place for those who want to stay connected to city life
                without being in the middle of it.
              </CommonParagraph>
              <CommonParagraph>2. Linda Vista</CommonParagraph>
              <CommonParagraph>
                A multicultural neighborhood renowned for its affordability, the community-oriented
                suburb of{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/linda-vista-homes-for-sale"
                  text="Linda Vista"
                />{" "}
                makes it an attractive option for retirees of all budgets. There's a great mix of
                single-family homes and apartments, ensuring all can find their dream retirement
                home.
              </CommonParagraph>
              <CommonParagraph>3. Chula Vista</CommonParagraph>
              <CommonParagraph>
                With a low crime rate and growing retiree population, Chula Vista is a fantastic
                community with a host of amenities. From the Chula Vista Marina to the Living Coast
                Discovery Center, parks, and shopping centers, there's never a dull moment.
              </CommonParagraph>
              <CommonParagraph>4. El Cajon</CommonParagraph>
              <CommonParagraph>
                El Cajon's friendly atmosphere, proximity to downtown San Diego, and easy access to
                healthcare facilities make it an excellent choice for retirees. Look forward to
                community events, strolls in local parks, and superb dining options.
              </CommonParagraph>
              <CommonParagraph>5. South Bay</CommonParagraph>
              <CommonParagraph>
                With neighborhoods like Imperial Beach and National City, the South Bay area offers
                a coastal lifestyle at an affordable price. Thanks to ongoing development and
                investments throughout the region, this area is becoming increasingly popular with
                retirees.
              </CommonParagraph>
              <Heading type="h2">
                Find the Perfect Place to Retire With Help From The Selby Team
              </Heading>
              <CommonParagraph>
                Ready to enjoy retirement? Let The Selby Team help you find the perfect place to
                call home! We look forward to showing you around the San Diego neighborhoods that
                will complement your lifestyle–
                <ContactSlideoutLink text="get started now" />!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
